import React, { useEffect, useState } from 'react';
import { FormGroup, TextField, Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import PasswordInput from 'components/login/PasswordInput';
import PasswordValidation from 'components/login/PasswordValidation';
import { checkEmailZod, validatePassword } from 'utils/validate';
import ErrorLabel from 'components/login/ErrorLabel';
import { alertObjectType } from 'utils/types';

const ActivateForm: React.FC<{
  username: string;
  password: string;
  setUsername: (newValue: string) => void;
  setPassword: (newValue: string) => void;
  errors: string;
  hasUsernameError: boolean;
  hasPasswordError: boolean;
  isLoading: boolean;
  onActivate: () => void;
  setAlert: (alertObject: alertObjectType | undefined) => void;
}> = ({
  username,
  password,
  setUsername,
  setPassword,
  errors,
  hasUsernameError,
  hasPasswordError,
  isLoading,
  onActivate,
  setAlert,
}) => {
  const [emailHelperText, setEmailHelperText] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);

  const [passwordError, setPasswordError] = useState('');

  const onEmailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailValue = e.target?.value;

    setUsername(emailValue ? emailValue : '');

    if (!emailValue) {
      setEmailHelperText('');
    }

    if (emailValue.length > 0 && emailValue) {
      const isValidated = checkEmailZod(emailValue);
      setEmailIsValid(isValidated);
      if (!isValidated) {
        setEmailHelperText('Must be email format.');
        return;
      }
      setEmailHelperText('');
    }
  };

  const checkPassword = (text: string) => {
    const validationError = validatePassword(text, username);

    if (validationError) {
      setPasswordError(validationError);
      setPassword(text);
      return;
    }

    setPasswordError('');
    setPassword(text);
  };

  const activateHandler = () => {
    if (username && password) {
      onActivate();
    }
  };

  useEffect(() => {
    if (errors) {
      setAlert({ type: 'warning', message: errors, timeout: 5000 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <React.Fragment>
      <FormGroup>
        <TextField
          autoComplete="username"
          sx={{ mb: 3, width: '100%' }}
          label={'Email address'}
          value={username}
          onChange={onEmailChangeHandler}
          error={hasUsernameError || !!emailHelperText}
          disabled={true}
          helperText={emailHelperText}
          autoFocus
        />
        <PasswordInput
          placeholder="Create a password"
          isLoading={isLoading}
          errors={passwordError}
          password={password}
          hasPasswordError={!!passwordError}
          onChange={checkPassword}
          onSubmitKeyHandler={activateHandler}
          isNewPassword
        />
        {passwordError && <ErrorLabel>{passwordError}</ErrorLabel>}

        <Box mt={1}>
          <PasswordValidation password={password}></PasswordValidation>
        </Box>

        <Box
          sx={{ mt: 1 }}
          display="flex"
          justifyContent="center"
          alignItems="baseline"
          flexDirection="column"
          width="inherit"
        >
          <LoadingButton
            type="submit"
            onClick={activateHandler}
            variant="contained"
            fullWidth={true}
            disabled={!!passwordError || hasPasswordError || isLoading}
            size="large"
            loading={isLoading}
          >
            Activate Account
          </LoadingButton>
        </Box>
      </FormGroup>
    </React.Fragment>
  );
};

export default ActivateForm;
