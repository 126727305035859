import React, { useEffect, useState } from 'react';
import { Alert, Box, Container, Fade } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import IdobaLogo from '../../components/icons/IdobaLogin';
import ResetPasswordForm from 'components/reset/ResetPasswordForm';
import LoginFooter from 'components/login/LoginFooter/LoginFooter';
import { alertObjectType } from 'utils/types';
import { useIdobaAuth } from '@optika-solutions/shared-react';

const Reset: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [hasPasswordError, setHasPasswordError] = useState(false);
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { authService } = useIdobaAuth();

  const [alertObject, setAlertObject] = useState<alertObjectType | undefined>(undefined);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (alertObject) {
      setShowAlert(true);
    }
    if (alertObject?.timeout) {
      const alertTimer = setTimeout(() => {
        setShowAlert(false);
      }, alertObject.timeout);

      return () => clearTimeout(alertTimer);
    }
  }, [alertObject]);

  const changePassword = () => {
    if (code) {
      setLoading(true);
      setErrors('');
      setHasPasswordError(false);
      authService
        .resetPassword(username, code, password)
        .then((result) => {
          //console.log(result);
          navigate('/modules');
          setLoading(false);
        })
        .catch((error: Error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const reset_code = params.get('code');
    const email = params.get('email');
    if (reset_code && email) {
      setUsername(email);
      setCode(reset_code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Container sx={{ mt: 22 }} maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 126,
            borderRadius: '4px',
            mb: '32px',
          }}
        >
          <IdobaLogo />
        </Box>

        <ResetPasswordForm
          password={password}
          setPassword={setPassword}
          errors={errors}
          hasPasswordError={hasPasswordError}
          isLoading={loading}
          onSubmit={changePassword}
          setAlert={setAlertObject}
        ></ResetPasswordForm>

        <LoginFooter />
      </Container>
      <Fade
        in={showAlert}
        easing={{ exit: 'ease-out' }}
        timeout={{ enter: 0, exit: 1000 }}
        onExited={() => setAlertObject(undefined)}
      >
        <Alert
          sx={{
            position: 'absolute',
            top: '56px',
            zIndex: 20,
          }}
          iconMapping={{
            success: <CheckCircleOutline fontSize="inherit" />,
          }}
          onClose={!alertObject?.timeout ? () => setShowAlert(false) : undefined}
          severity={alertObject?.type}
        >
          {alertObject?.message}
        </Alert>
      </Fade>
    </Box>
  );
};

export default Reset;
